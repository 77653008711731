html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}